import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { CommonService } from './service/common.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from './service/user.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private userService: UserService,
    public _commonService: CommonService,
    public cookieService: CookieService,
    private toastr: ToastrService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this._commonService.show();
    const authToken = this.cookieService.get('authToken');
    if (authToken && !this.isTokenExpired(authToken)) {
      const authReq = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + authToken) });
      if (req.url.includes('questionUpload')) {
        const modifiedReq = req.clone({ headers: req.headers.set('Authorization', 'Bearer ') });
        return next.handle(modifiedReq);
      }
      return next.handle(authReq).pipe(
        catchError(error => {
          // Handle 403 errors or specific authentication-related errors
          if (error.status === 403 || error.error?.detail === 'Token has expired' || error?.detail === 'Authentication credentials were not provided.') {
            this.clearSession(); // Clear session on token expiration or authentication error
            this.router.navigate(['/login']);
          }
          return throwError(error?.error?.detail || error?.detail);
        }),
        finalize(() => {
          this._commonService.hide();
        })
      );
    } else {
      this.clearSession();
      this._commonService.hide();
      // this.router.navigate(['/login']);
      return next.handle(req);
    }
  }

  // Helper method to determine if the token is expired
  private isTokenExpired(token: string): boolean {
    const expiryDate = this.getTokenExpiryDate(token);
    if (!expiryDate) return false;
    return expiryDate.getTime() < new Date().getTime();
  }

  // Helper to extract the expiration date from the JWT
  private getTokenExpiryDate(token: string): Date | null {
    try {
      const payload = JSON.parse(atob(token.split('.')[1])); // Decode the token payload
      if (payload.exp) {
        return new Date(payload.exp * 1000); // Convert expiry time to JavaScript Date object
      }
      return null;
    } catch (e) {
      return null;
    }
  }

  private clearSession() {
    localStorage.clear();
    this.cookieService.deleteAll();
  }

}
